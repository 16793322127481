@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$theme-colors: (
    // "site-success": #0ec60e,
    "site-success": #2e8b57,
    "site-lite-grey": #f6f9fc
);
@import "node_modules/bootstrap/scss/bootstrap";


@for $size from 5 through 96 {
    .fs--#{$size} {
        font-size: $size/16 + rem;
    }
}

@for $i from 100 through 900 {
    .fw--#{$i} {
        font-weight: $i;
    }
}

.league-font {
    font-family: "League Spartan", sans-serif;
}

.headerButtonSchedule {
    // background-color: #fa6e62;
    color: #fff;
    padding: 0.7em 1.2em 0.6em 1.2em;
    border-radius: 2em;
    // margin-top: 2rem;
    display: inline-block;
    font-family: "League Spartan";
    -webkit-letter-spacing: 0;
    -moz-letter-spacing: 0;
    -ms-letter-spacing: 0;
    letter-spacing: 0;
}

.navbar-expand-lg .navbar-nav .nav-link {
    border-bottom: 2px solid transparent;
    color: #000;
    cursor: pointer;
    font-size: 1.2em;
    text-align: left;
    font-weight: 500;
    -webkit-transition: 0.3s ease all;
    transition: 0.3s ease all;
    width: 100%;
    position: relative;
    padding: 0.5rem 2rem;

    @media screen and (max-width: 1200px) {
        padding: 0.5rem 1rem;
    }
}

.navbar-section {
    @media screen and (max-width: 991.5px) {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        background-color: white;
        position: fixed;
        z-index: 2;
        width: 100%;
    }
}

.navbar {
    .navbar-brand {
        display: flex;
        align-items: center;

        & h2 {
            font-size: 20px;
            font-weight: 400;
            margin: 0;
            margin-left: 5px;
        }
    }

    .navbar-nav {
        @media screen and (max-width: 991.5px) {
            position: fixed;
            left: 0;
            margin-top: 5%;
            z-index: 2;
            background-color: white;
            width: 100%;
            height: 100vh;
        }

        @media screen and (max-width: 756.5px) {
            position: fixed;
            left: 0;
            margin-top: 5%;
            z-index: 2;
            background-color: white;
            width: 100%;
            height: 100vh;
        }

        @media screen and (max-width: 590.5px) {
            position: fixed;
            left: 0;
            margin-top: 9%;
            z-index: 2;
            background-color: white;
            width: 100%;
            height: 100vh;
        }

        @media screen and (max-width: 400.5px) {
            position: fixed;
            left: 0;
            margin-top: 14%;
            z-index: 2;
            background-color: white;
            width: 100%;
            height: 100vh;
        }
    }
}

.hero-section {
    position: relative;
    margin-top: 90px;

    & h1 {
        font-size: 80px;
        font-weight: 800;
        color: #fff;
        margin-top: 0;
        margin-bottom: 30px;
        line-height: 1;

        & div {
            // background: -webkit-linear-gradient(
            //     left,
            //     map-get($theme-colors, "site-success"),
            //     #2e8b57,
            //     #61bc84,
            //     #61bc84
            // );
            background: #2e8b57;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            height: 1.2em;
        }
    }

    & h3 {
        -webkit-letter-spacing: 0.06em;
        -moz-letter-spacing: 0.06em;
        -ms-letter-spacing: 0.06em;
        letter-spacing: 0.06em;
        color: #fff;
        margin: 0 auto;
        line-height: 1.4em;
        font-weight: 300;
        font-size: 22px;
        font-weight: 300;
    }

    .main-p {
        font-family: "League Spartan", sans-serif;

        // max-width: 45rem;
        & b {
            &:nth-of-type(1) {
                color: #ffa950;
            }

            &:nth-of-type(2) {
                color: #ff51a5;
            }

            &:nth-of-type(3) {
                color: #ab3ff8;
            }

            &:nth-of-type(4) {
                color: #24b6c6;
            }

            &:nth-of-type(5) {
                color: map-get($theme-colors, "site-success");
            }
        }
    }

    .hero-left {
        & button {
            position: relative;
            overflow: hidden;
            margin: 1.6em 0 0 0;
            padding: 1em 3.5em 0.8em 3.5em;
            border: 0;
            // color: #fff;
            // background-image: linear-gradient(
            //     to right,
            //     #aafaa5,
            //     #0ec60e,
            //     #008000
            // );
            background: #2e8b57;

            @media screen and (max-width: 776.5px) {
                padding: 0.5em 3em 0.3em 3em;
            }

            @media screen and (max-width: 600.5px) {
                padding: 0.5em 3em 0.3em 3em;
            }
        }
    }

    &__background-video-container {
        position: relative;
        width: 100%;
        height: 550px;
        overflow: hidden;

        @media screen and (max-width: 600px) {
            height: 450px;
        }
    }

    &__background-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.747);
    }

    @media screen and (max-width: 1200px) {
        h1 {
            font-size: 65px;
        }

        h3 {
            font-size: 20px;
        }
    }

    @media screen and (max-width: 990px) {
        padding-top: 80px !important;

        h1 {
            font-size: 50px;
        }

        h3 {
            font-size: 18px;
        }
    }

    @media screen and (max-width: 768px) {
        padding-top: 100px !important;

        h1 {
            font-size: 45px;
        }

        h3 {
            font-size: 17px;
        }
    }

    @media screen and (max-width: 600px) {
        padding-top: 40px !important;

        h1 {
            font-size: 35px;
        }

        h3 {
            font-size: 16px;
        }
    }

    @media screen and (max-width: 375px) {
        padding-top: 40px !important;

        h1 {
            font-size: 25px;
        }

        h3 {
            font-size: 15px;
        }
    }
}



.let-s-talk-btn {
    margin-top: 10px;
    padding: 10px 14px;
    border-radius: 4px;
    border: 0;
    // color: #fff;
    background-image: linear-gradient(to right, #aafaa5, #0ec60e, #008000);
    // & span {
    //     background: linear-gradient(85.71deg, #2e8b57 80.64%, #457255e0 2.59%);
    //     -webkit-background-clip: text;
    //     -webkit-text-fill-color: transparent;
    // }
}

.separatorWrap {
    & svg {
        -webkit-transform: scaleX(2.5);
        margin: -2rem;
    }

    overflow: hidden;
}

.How-we-work {
    font-family: "League Spartan", Roboto Thin, Helvetica, Arial, sans-serif;
    font-size: 1.25rem;
    color: rgb(51, 51, 51);
    margin-bottom: 1em;
    letter-spacing: 0.05rem;

    & h1 {
        font-family: "Poppins", sans-serif;
        font-size: 3rem;
        -webkit-letter-spacing: -3%;
        -moz-letter-spacing: -3%;
        -ms-letter-spacing: -3%;
        letter-spacing: -3%;
        color: #000;
        margin-top: 1.2rem;
        font-weight: 700;
        padding-bottom: 1.8rem;

        & span {
            background: linear-gradient(85.71deg, #2e8b57, #4ed488);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        @media screen and (max-width: 767.5px) {
            font-size: 2rem;
            text-align: center;
        }
    }

    & svg {
        margin-right: 4px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        stroke-width: 5;
        stroke: #fff;
        stroke-miterlimit: 10;
        background-color: map-get($theme-colors, "site-success");
        // box-shadow: inset 0px 0px 0px #5af0bc;
    }

    & h2 {
        font-size: 20px;
        font-weight: 400;

        @media screen and (max-width: 767.5px) {
            font-size: 1rem;
        }

        @media screen and (max-width: 367.5px) {
            font-size: 0.7rem;
        }
    }

    & p {
        @media screen and (max-width: 767.5px) {
            font-size: 1rem;
        }

        @media screen and (max-width: 367.5px) {
            font-size: 0.7rem;
        }
    }
}

.what-we-offer {
    & li {
        font-family: "League Spartan", Helvetica, Arial, sans-serif;
    }

    & h1 {
        font-family: Poppins, sans-serif;
        font-size: 3rem;
        color: rgb(0, 0, 0);
        margin-bottom: 2rem;
        margin-top: 1.2rem;
        font-weight: 800;

        @media screen and (max-width: 767.5px) {
            font-size: 2rem;
            text-align: center;
        }
    }

    & svg {
        height: 1.5em;
    }

    & span {
        background: -webkit-linear-gradient(left,
                map-get($theme-colors, "site-success"),
                #2e8b57,
                #4ed488);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    & h2 {
        margin-top: 10px;
        font-size: 1.3rem;
    }

    // Animation styles
    & .card-container {
        animation: slideIn 1.3s ease-in-out;
    }

    @keyframes slideIn {
        from {
            opacity: 0;
            transform: translateY(-50px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.services {

    // background-color: #f6f9fc;
    & li {
        font-family: "League Spartan", Helvetica, Arial, sans-serif;
    }

    & h2 {
        color: map-get($theme-colors, "site-success");
        font-family: Poppins, sans-serif;
        font-size: 3rem;
        color: rgb(0, 0, 0);
        margin-bottom: 4.5rem;
        margin-top: 1.2rem;
        font-weight: 800;

        @media screen and (max-width: 767.5px) {
            font-size: 2rem;
        }
    }

    & h3 {
        font-family: Poppins, sans-serif;
        font-size: 13px;
    }

    & svg {
        height: 1.5em;
    }

    & h4 {
        border-bottom: 2px solid map-get($theme-colors, "site-success");
    }

    .heading {
        color: map-get($theme-colors, "site-success");
        font-size: 17px;
        font-weight: 500;
        margin: 0px;
        padding: 5px 0px;
    }

    & h1 {
        font-family: Poppins, sans-serif;
        font-size: 3rem;
        color: rgb(0, 0, 0);
        margin-bottom: 2rem;
        margin-top: 1.2rem;
        font-weight: 800;

        @media screen and (max-width: 767.5px) {
            font-size: 2rem;
            text-align: center;
        }
    }

    & span {
        background: -webkit-linear-gradient(left,
                map-get($theme-colors, "site-success"),
                #2e8b57,
                #4ed488);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.testimonials {
    background-color: #f6f9fc;

    .slider {
        display: flex;
        overflow-x: hidden;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .slider-btn {
        font-size: 45px;
        color: #2e8b56c4;
        cursor: pointer;

        &:hover {
            color: #2e8b57;
        }
    }

    & h1 {
        font-family: "Poppins", sans-serif;
        font-size: 3rem;
        -webkit-letter-spacing: -3%;
        -moz-letter-spacing: -3%;
        -ms-letter-spacing: -3%;
        letter-spacing: -3%;
        color: #000;
        margin-bottom: 1.5rem;
        font-weight: 700;

        @media screen and (max-width: 767.5px) {
            font-size: 2rem;
            text-align: center;
            // margin-bottom: 0.75rem;
        }

        & span {
            background: -webkit-linear-gradient(left,
                    map-get($theme-colors, "site-success"),
                    #2e8b57,
                    #4ed488);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .card {
        border: none !important;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        padding: 10px;
        width: 100%;
    }

    // .slick-dots {
    //     position: block !important;
    // }

    .name {
        font-size: 1rem;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
    }

    .role {
        font-size: 0.8rem;
        opacity: 0.5;
        font-weight: 600;
        font-family: "League Spartan", sans-serif;
        margin-bottom: 0;
    }

    .human {
        outline: none !important;
        margin-right: 10px;

        & img {
            border-radius: 50%;
        }
    }
}

.footer {
    background-color: #f6f9fc;
    font-family: "League Spartan", Roboto Thin, Helvetica, Arial, sans-serif;

    & h4 {
        font-family: "Inter", Sans-serif;
        font-size: 18px;
        font-weight: 700;
        margin-top: 14px;
    }

    & p {
        font-family: "Roboto", Sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
    }

    & h5 {
        font-family: "Inter", Sans-serif;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 14px;
    }

    & ul {
        & li {
            margin-bottom: 10px;
            font-family: "Roboto", Sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 25px;
        }
    }

    .social-media {
        & span {
            margin-right: 10px;

            & svg {
                font-size: 30px;
            }
        }

        & a {
            text-decoration: none;
            /* Remove underline on hover */
            color: inherit;
            /* Use the default text color */
        }

        & a:hover {
            text-decoration: none;
            /* Remove underline on hover */
            color: inherit;
            /* Use the default text color */
        }
    }
}

.plans-section {
    & h1 {
        font-family: "Poppins", sans-serif;
        font-size: 3rem;
        -webkit-letter-spacing: -3%;
        -moz-letter-spacing: -3%;
        -ms-letter-spacing: -3%;
        letter-spacing: -3%;
        color: #000;
        margin-bottom: 1.5rem;
        font-weight: 700;

        @media screen and (max-width: 767.5px) {
            font-size: 2rem;
            text-align: center;
            // margin-bottom: 0.75rem;
        }

        & span {
            background: -webkit-linear-gradient(left,
                    map-get($theme-colors, "site-success"),
                    #2e8b57,
                    #4ed488);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .card {
        border-radius: 10px;
        box-shadow: 0px 0px 34px -10px rgba(0, 0, 0, 0.22);
        border: 0px solid rgba(0, 0, 0, 0.125) !important;
    }

    .card-title {
        height: 67px;
    }

    .plans-heading {
        font-size: 22px;
        font-weight: 700;
    }

    & h5 {
        color: #171717;
        font-family: "Inter", Sans-serif;
        font-size: 26px;
        font-weight: 800;

        @media screen and (max-width: 1200px) and (min-width: 992px) {
            font-size: 23px;
        }
    }

    & p {
        text-align: center;
        color: #121212;
        font-family: "Roboto", Sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
    }

    & ul {
        padding: 0px;

        & svg {
            margin-right: 10px;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
            stroke-width: 5;
            stroke: #fff;
            stroke-miterlimit: 10;
            background-color: #0ec60e;
            // box-shadow: inset 0px 0px 0px #5af0bc;
        }

        & li {
            position: relative;
            padding: 0 0 21px 32px;
            list-style: none;
            text-align: left;
        }
    }
}

.basic-plans-section {
    & h1 {
        font-family: "Poppins", sans-serif;
        font-size: 3rem;
        -webkit-letter-spacing: -3%;
        -moz-letter-spacing: -3%;
        -ms-letter-spacing: -3%;
        letter-spacing: -3%;
        color: #000;
        margin-bottom: 1.5rem;
        font-weight: 700;

        @media screen and (max-width: 767.5px) {
            font-size: 1.75rem;
            text-align: center;
            margin-bottom: 0.75rem;
        }

        & span {
            background: -webkit-linear-gradient(left,
                    map-get($theme-colors, "site-success"),
                    #2e8b57,
                    #4ed488);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .card {
        border-radius: 10px;
        box-shadow: 0px 0px 34px -10px rgba(0, 0, 0, 0.22);
        border: 0px solid rgba(0, 0, 0, 0.125) !important;
    }

    .basic-plans-heading {
        font-size: 30px;
        font-weight: 700;
    }

    & h5 {
        color: #171717;
        font-family: "Inter", Sans-serif;
        font-size: 25px;
        font-weight: 700;

        @media screen and (max-width: 1200px) and (min-width: 992px) {
            font-size: 23px;
        }
    }

    & ul {
        padding: 0px;

        & svg {
            margin-right: 10px;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
            stroke-width: 5;
            stroke: #fff;
            stroke-miterlimit: 10;
            background-color: #0ec60e;
            // box-shadow: inset 0px 0px 0px #5af0bc;
        }

        & li {
            position: relative;
            padding: 0 0 21px 32px;
            list-style: none;
            text-align: left;
        }
    }
}

.questions-section {
    background-color: #f6f9fc;

    .accordion-button.collapsed {
        text-transform: uppercase;
        font-size: 20px !important;
        font-weight: 600;
        color: map-get($theme-colors, "site-success");
    }

    .accordion-button {
        font-family: "Inter", Sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 1rem 1.25rem;
        color: #212529;
        text-align: left;
        background-color: #fff;
        border: 0;
        border-radius: 0;
        overflow-anchor: none;
        transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out,
            border-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out,
            border-radius 0.5s ease;

        @media screen and (max-width: 991.5px) {
            padding: 0px;
        }
    }

    .accordion-button::after {
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
        margin-left: auto;
        content: "";
        background-image: url(../img/download.svg);
        background-repeat: no-repeat;
        background-size: 1.25rem;
        transition: transform 0.2s ease-in-out;
    }

    .accordion-button.collapsed::after {
        background-image: url(../img/download.svg);
        transform: rotate(-180deg);
    }

    .show {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background: map-get($theme-colors, "site-success");
            width: 6px;
            height: 100%;
        }
    }

    .accordion-body {
        color: #000000;
        font-family: "Roboto", Sans-serif;
        font-size: 18px;
        font-weight: 400;
    }

    .question-left {
        & h2 {
            font-family: "Roboto", Sans-serif;
            font-size: 18px;
            font-weight: 500;
            text-transform: uppercase;
            color: map-get($theme-colors, "site-success");
        }

        & h3 {
            color: #171717;
            font-family: "Inter", Sans-serif;
            font-size: 40px;
            font-weight: 800;
            text-transform: capitalize;
            line-height: 60px;

            & span {
                color: map-get($theme-colors, "site-success");
            }
        }

        & p {
            color: #171717;
            font-family: "Roboto", Sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 26px;
        }

        & button {
            font-family: "Inter", Sans-serif;
            font-size: 18px;
            font-weight: 500;
            background: -webkit-linear-gradient(left,
                    map-get($theme-colors, "site-success"),
                    #2e8b57,
                    #457255e0);
            border-radius: 5px 5px 5px 5px;
        }
    }
}

.ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 600ms linear;
    background-color: rgba(255, 255, 255, 0.7);
}

@keyframes ripple {
    to {
        transform: scale(4);
        opacity: 0;
    }
}

/* <reset-style> ============================ */
.custom-menu {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    font-family: inherit;
}

/* ============================ */
/* <style for bg> ======== */

/* <style for menu__icon> ======== */
.menu__icon {
    width: 32px;
    height: 32px;
    padding: 4px;
}

.menu__icon span {
    display: block;
    width: 100%;
    height: 0.125rem;
    border-radius: 2px;
    background-color: rgb(46, 139, 87);
    box-shadow: 0 0.5px 2px 0 hsla(0, 0%, 0%, 0.2);
    transition: background-color 0.4s;
    position: relative;
}

.menu__icon span+span {
    margin-top: 0.375rem;
}

.menu__icon span:nth-child(1) {
    animation: ease 0.8s menu-icon-top-2 forwards;
}

.menu__icon span:nth-child(2) {
    animation: ease 0.8s menu-icon-scaled-2 forwards;
}

.menu__icon span:nth-child(3) {
    animation: ease 0.8s menu-icon-bottom-2 forwards;
}

.menu__icon.clicked span:nth-child(1) {
    animation: ease 0.8s menu-icon-top forwards;
}

.menu__icon.clicked span:nth-child(2) {
    animation: ease 0.8s menu-icon-scaled forwards;
}

.menu__icon.clicked span:nth-child(3) {
    animation: ease 0.8s menu-icon-bottom forwards;
    background-color: rgb(46, 139, 87);
}

@keyframes menu-icon-top {
    0% {
        top: 0;
        transform: rotate(0);
    }

    50% {
        top: 0.5rem;
        transform: rotate(0);
    }

    100% {
        top: 0.5rem;
        transform: rotate(45deg);
    }
}

@keyframes menu-icon-top-2 {
    0% {
        top: 0.5rem;
        transform: rotate(45deg);
    }

    50% {
        top: 0.5rem;
        transform: rotate(0);
    }

    100% {
        top: 0;
        transform: rotate(0);
    }
}

@keyframes menu-icon-bottom {
    0% {
        bottom: 0;
        transform: rotate(0);
    }

    50% {
        bottom: 0.5rem;
        transform: rotate(0);
    }

    100% {
        bottom: 0.5rem;
        transform: rotate(135deg);
    }
}

@keyframes menu-icon-bottom-2 {
    0% {
        bottom: 0.5rem;
        transform: rotate(135deg);
    }

    50% {
        bottom: 0.5rem;
        transform: rotate(0);
    }

    100% {
        bottom: 0;
        transform: rotate(0);
    }
}

@keyframes menu-icon-scaled {
    50% {
        transform: scale(0);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes menu-icon-scaled-2 {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

.cursor-pointer {
    cursor: pointer;
}

.gradient-text {
    background: linear-gradient(85.71deg, #4ed488 2.59%, #2e8b57 48.64%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    max-height: 80vh;
    font-family: "League Spartan", sans-serif;
    padding: 0px 50px;

    .lets-talk-Modal {
        .input-container {
            position: relative;
            width: 100%;
            margin: 5px 0;
        }

        .input-container input[type="text"] {
            font-size: 20px;
            font-weight: 400;
            width: 100%;
            border: none;
            border-bottom: 1px solid #ccc;
            background-color: transparent;
            outline: none;
        }

        .input-container .label {
            position: absolute;
            top: 0;
            left: 0;
            color: #ccc;
            transition: all 0.3s ease;
            pointer-events: none;
        }

        .input-container input[type="text"]:focus~.label,
        .input-container input[type="text"]:valid~.label {
            top: -20px;
            font-size: 16px;
            color: #333;
        }

        .input-container .underline {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
            background-color: #1f88d9;
            transform: scaleX(0);
            transition: all 0.3s ease;
        }

        .input-container input[type="text"]:focus~.underline,
        .input-container input[type="text"]:valid~.underline {
            transform: scaleX(1);
        }

        .error-message {
            color: red;
            font-size: 12px;
        }
    }
}

// .slick-prev {
//     margin-left: 8px;
//     z-index: 999;
//     font-size: 0;
//     line-height: 0;
//     position: absolute;
//     top: 50%;
//     display: block;
//     width: 10px;
//     height: 20px;
//     padding: 0;
//     transform: translate(0, -50%);
//     cursor: pointer;
//     color: transparent;
//     border: none;
//     outline: none;
//     background: transparent;
// }

// .slick-next {
//     margin-right: 37px;
//     z-index: 999;
//     font-size: 0;
//     line-height: 0;
//     position: absolute;
//     top: 50%;
//     display: block;
//     width: 10px;
//     height: 20px;
//     padding: 0;
//     transform: translate(0, -50%);
//     cursor: pointer;
//     color: transparent;
//     border: none;
//     outline: none;
//     background: transparent;
// }

// .slick-prev:before,
// .slick-next:before {
//     font-family: "slick";
//     font-size: 37px;
//     line-height: 1;
//     opacity: 0.75;
//     color: #2e8b57;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
// }

.plan-card {
    transition: box-shadow 0.3s ease-in-out,
        transform 0.3s ease-in-out;
}

.plan-card:hover {
    // color: #fff;

    // background-image: linear-gradient(to bottom, #2e8b57, #57f89d);
    cursor: pointer;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    transform: scale(1.05);

    /* Add your desired scaling factor */
    Button {
        // background-color: #fff !important;
        // color: map-get($theme-colors, "site-success");
    }
}

.wrapper {
    position: relative;
    z-index: 1;
}

.ribbon-wrapper {
    width: 75px;
    height: 90px;
    position: absolute;
    top: -3px;
    right: -3px;
}

.ribbon-wrapper-bottom {
    width: 75px;
    height: 74px;
    position: absolute;
    bottom: -1px;
    left: 2px;
}

.ribbon-top {
    width: 70px;
    height: 76px;
    background: none;
    border-left: 8px solid #401f7c7a;
    border-top: 8px solid #401f7c7a;
    border-radius: 100px 0 0 0;
    -moz-border-radius: 100px 0 0 0;
    -webkit-border-radius: 100px 0 0 0;
    transform: rotate(270deg);
}

.ribbon-bottom {
    width: 70px;
    height: 76px;
    background: none;
    border-left: 8px solid #2e8b56ab;
    border-top: 8px solid #2e8b56ab;
    border-radius: 100px 0 0 0;
    -moz-border-radius: 100px 0 0 0;
    -webkit-border-radius: 100px 0 0 0;
    transform: rotate(90deg);
}

.calendly {
    .calendly-inline-widget {
        min-width: 220px;
        height: 75%;
    }

    & h2 {
        font-size: 30px;
    }

    @media screen and (max-width: 426.5px) {
        h1 {
            font-size: x-large;
        }

        p {
            font-size: medium;
        }
    }
}

:root {
    // --PhoneInput-color--focus: #03b2cb;
    --PhoneInputInternationalIconPhone-opacity: 0.8;
    --PhoneInputInternationalIconGlobe-opacity: 0.65;
    --PhoneInputCountrySelect-marginRight: 0.35em;
    --PhoneInputCountrySelectArrow-width: 0.3em;
    --PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
    // --PhoneInputCountrySelectArrow-borderWidth: 1px;
    --PhoneInputCountrySelectArrow-opacity: 0.45;
    --PhoneInputCountrySelectArrow-color: currentColor;
    // --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
    --PhoneInputCountrySelectArrow-transform: rotate(45deg);
    --PhoneInputCountryFlag-aspectRatio: 1.5;
    --PhoneInputCountryFlag-height: 1em;
    // --PhoneInputCountryFlag-borderWidth: 1px;
    // --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
    // --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
    --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.PhoneInput {
    display: flex;
    align-items: center;
    font-size: 20px;
}

.PhoneInputInput:focus,
.PhoneInputInput:active {
    outline: none;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #1f88d9;
}

.PhoneInputInput {
    flex: 1;
    min-width: 0;
    border: none;
    border-bottom: 1px solid #1f88d9;
}

.PhoneInputCountryIcon {
    width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
    height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
    width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
    background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
        inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
    display: block;
    width: 100%;
    height: 100%;
}

.PhoneInputInternationalIconPhone {
    opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
    opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

.PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
    cursor: default;
}

.PhoneInputCountrySelectArrow {
    display: block;
    content: "";
    width: var(--PhoneInputCountrySelectArrow-width);
    height: var(--PhoneInputCountrySelectArrow-width);
    margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
    border-style: solid;
    border-color: var(--PhoneInputCountrySelectArrow-color);
    border-top-width: 0;
    border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    border-left-width: 0;
    border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    transform: var(--PhoneInputCountrySelectArrow-transform);
    opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus+.PhoneInputCountryIcon+.PhoneInputCountrySelectArrow {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus+.PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
        inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus+.PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
}